import {hostName} from './HostName';
import axios from 'axios';
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

export const instance = axios.create({
    baseURL : `${hostName}`,
    timeout: 5000,
    withCredentials: true,
  });


// this function always return a string
// whether the input is a string or a number
export function usd_trim(amount){
  let num_input = Number(amount);
  return num_input.toFixed(2);
}

// this function always return a string
// whether the input is a string or a number
export function crypto_trim(current_price, unit_price){
    
    let num_input = Number(unit_price);
    var precision = Math.floor(unit_price).toString(10).length + 2;

    let quantity =  (current_price / num_input).toFixed(precision);
    return quantity;
}


// this function always return a string
// whether the input is a string or a number
export function account_trim(account){
    let first_three = account.slice(0, 5);
    let last_four = account.slice(account.length -4, account.length);
    return `${first_three}...${last_four}`;
  }



export function parseDateTime(date){
      
    let strDateTime = "";
    let parsedYear = date.getFullYear().toString().slice(-2); 
    let parsedMonth = date.getMonth()+ 1;
   
    // miliseconds comparison
    let currentDate = new Date;
    if(currentDate.getTime() - date.getTime() >= 1000 * 60 *  60* 24 * 7){
      if(currentDate.getFullYear() != date.getFullYear()){
        strDateTime = `${parsedMonth}/${date.getDate()}/${parsedYear} ${date.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}`;
      }
      else{
        strDateTime = `${parsedMonth}/${date.getDate()}  ${date.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}`;
      }
    }
    else{
      let day = date.toLocaleString('en-us', {weekday : 'short'});       
      
      strDateTime = `${day} ${date.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}`;
    }

    return strDateTime;
}


export function chatDateTime(date){
      
    let strDateTime = "";
    let parsedYear = date.getFullYear().toString().slice(-2); 
    let parsedMonth = date.getMonth()+ 1;
   
    let currentDate = new Date();
    let time = date.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
    if(time.charAt(0) == '0'){
        time = time.replace("0", "");
    }
    if(currentDate.getMinutes() - date.getMinutes() >= 60 * 24 * 7){
      if(currentDate.getFullYear() != date.getFullYear()){
        strDateTime = `${parsedMonth}/${date.getDate()}/${parsedYear}, ${time}`;
      }
      else{
        strDateTime = `${parsedMonth}/${date.getDate()}, ${time}`;
      }
    }
    else{
      let day = date.toLocaleString('en-us', {weekday : 'short'});       
      
      strDateTime = `${day}, ${time}`;
    }

    return strDateTime;
}

