import React, { useState } from "react";
import { Link } from "react-router-dom";
import {useNavigate} from "react-router-dom";
import SideNavbar from './SideNavbar.js';
import {hostName} from './HostName';

export default function Navbar({location}) {
  
  let navigate = useNavigate();
  const [dropDownMenu, setDropDown] = useState(false);
  
  const [navBar, setnavBar] = useState('-translate-x-full');

  function signin(){
    navigate('/signin', {replace : true});
}

function signup(){
  navigate('/signup', {replace : true});
}

  if(location == "loggedin"){
    return(
      <>
        <div className="px-6 w-full pt-2 flex lg2:hidden items-center justify-between">
        <div className="h-fit"><Link to="/"><img width={120} src={`${hostName}/static/decentral_logo_small.png`} alt="Decentral Logo" /></Link></div>
              <div className="flex items-center gap-2">
              
              <button onClick={()=> {navBar === "-translate-x-full" ? setnavBar('translate-x-0') : setnavBar('-translate-x-full')}} className='z-[999999] bg-white w-[45px] flex items-center justify-center h-[54px] rounded-xl border border-[#F2F2F2]'>{navBar === "-translate-x-full" ? <img src={`${hostName}/static/menu.svg`} className='w-[25px]' alt="icon" /> : <img src={`${hostName}/static/close.svg`} alt="icon" />}</button>
              </div>
        </div>
        <div className={`${navBar} lg2:translate-x-0 transition fixed pr-[75px] lg2:pr-0 max-w-[300px] lg2:max-w-[100%] w-full top-0 left-0 z-[9999] lg2:relative lg2:top-auto lg2:left-auto`}><SideNavbar/></div>
        <div onClick={()=> setnavBar('-translate-x-full')} className={`${navBar === "-translate-x-full" ? "opacity-0 invisible" : "opacity-100 visible"} transition lg2:hidden fixed top-0 z-[999] left-0 w-screen h-screen bg-[#11047A33] bg-opacity-20 pr-4`}></div>
      </>
    );
  }
  else{

  return (
    <div>
      <nav className=" bg-white px-2 smL:px-4 py-2.5 dark:bg-gray-900  w-full z-20 top-0 left-0 " style={{shadow: '0px 30px 60px 0px #0000000D'}}>
        <div
          className="shadow-[0px_30px_60px_0px_rgba(0,0,0,0.05)]  rounded-[20px] p-[20px_20px_0px_20px] w-11/12 lgL:w-10/12 2xlL:w-8/12 ml-auto mr-auto  flex flex-wrap justify-between items-center mx-auto"
          bis_skin_checked="1"
        >
        <Link to="/home" className="flex items-center">
            <img src={`${hostName}/static/decentral_logo_small.png`} className="mr-3 mdL:h-[30px] smL:h-[17px] h-[15px]" alt="Decentral Logo" />
        </Link>
          <div className="flex mdL:order-2" bis_skin_checked="1">
            <button
              type="button"
              className="text-primary bg-primary-light   focus:ring-4 focus:outline-none  rounded-3xl text-xsLa hidden smL:block mdL:text-smLa px-6 py-3 text-center f-f-sm  mr-3 "
              onClick={signup}
            >
              Sign up
            </button>
            <button
              type="button"
              className="text-white bg-primary  focus:ring-4 focus:outline-none  rounded-3xl text-xsLa mdL:text-smLa px-6 py-3 text-center f-f-sm mr-3 mdL:mr-0 "
              onClick={signin}
            >
                Login
            </button>
            <button
              type="button"
              onClick={() => {
                setDropDown(!dropDownMenu);
              }}
              className="inline-flex items-center p-2 text-smLa text-gray-500 rounded-lg mdL:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-6 h-6"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          <div
            className={`${
              dropDownMenu ? "block" : " hidden"
            }   justify-between items-center w-full mdL:flex mdL:w-auto mdL:order-1`}
          >
            <ul className="flex flex-col pt-4 mt-4 bg-gray-50 rounded-lg border border-gray-100 mdL:flex-row mdL:space-x-3 xlL:space-x-8 mdL:mt-0 mdL:text-smLa mdL:font-medium mdL:border-0 mdL:bg-white dark:bg-gray-800 mdL:dark:bg-gray-900 dark:border-gray-700">
              <li>
                {
                dropDownMenu && window.innerWidth < 775?
                
		            <Link to="/home"
                  className={ location == "landing" ? "block py-2 pr-4 pl-3 text-white f-f-b bg-primary rounded mdL:bg-transparent mdL:text-black mdL:p-0 dark:text-white" : "block py-2 pr-4 pl-3 text-grey  f-f-r text-smLa  rounded hover:text-white  hover:bg-primary mdL:hover:bg-transparent mdL:hover:text-primary mdL:p-0" }
                  aria-current="page"
                >
                  Home
                </Link>

                :
                <Link to="/home"
                  className={ location == "landing" ? "block pb-[10px] pr-4 pl-3 border-b-2 border-[#0085FF] text-[#0085FF] rounded-none  f-f-b bg-primary rounded mdL:bg-transparent   dark:text-white" : "block py-2 pr-4 pl-3 text-grey    f-f-r text-smLa  rounded hover:text-white  hover:bg-primary mdL:hover:bg-transparent mdL:hover:text-blue-700 mdL:p-0 "}
                  aria-current="page"
                >
                  Home
                </Link>
              }
              </li>
              <li className="ml-0 mt-2 mdL:mt-0">
              {
              dropDownMenu && window.innerWidth < 775 ?
             
              <Link to="/signin"
                  className={ location == "signin" ? "block py-2 pr-4 pl-3 text-grey    f-f-r text-smLa  rounded hover:text-white  hover:bg-primary mdL:hover:bg-transparent mdL:hover:text-blue-700 mdL:p-0" : "block py-2 pr-4 pl-3 text-grey f-f-r text-smLa  rounded hover:text-white  hover:bg-primary mdL:hover:bg-transparent mdL:hover:text-primary mdL:p-0"}
              >
                  Sign In
              </Link>
                :
              <Link to="/signin"
                  className={ location == "signin" ? "block pb-[10px] pr-4 pl-3 border-b-2 border-[#0085FF] text-[#0085FF] rounded-none  f-f-b bg-primary rounded mdL:bg-transparent   dark:text-white" : "block py-2 pr-4 pl-3 text-grey    f-f-r text-smLa  rounded hover:text-white  hover:bg-primary mdL:hover:bg-transparent mdL:hover:text-blue-700 mdL:p-0 "}
                  aria-current="page"
                >
                  Sign In
              </Link>
              }
                
              </li>
              
              <li className="ml-0 mt-2 mdL:mt-0">
              {
              dropDownMenu && window.innerWidth < 775 ?
              <Link to="/signup"
                  className={ location == "signup" ? "block py-2 pr-4 pl-3 text-grey    f-f-r text-smLa  rounded hover:text-white  hover:bg-primary mdL:hover:bg-transparent mdL:hover:text-blue-700 mdL:p-0 " : "block py-2 pr-4 pl-3 text-grey f-f-r text-smLa  rounded hover:text-white  hover:bg-primary mdL:hover:bg-transparent mdL:hover:text-primary mdL:p-0"}
                >
                  Sign Up
              </Link>
                :
              <Link to="/signup"
                  className={ location == "signup" ? "block pb-[10px] pr-4 pl-3 border-b-2 border-[#0085FF] text-[#0085FF] rounded-none  f-f-b bg-primary rounded mdL:bg-transparent   dark:text-white" : "block py-2 pr-4 pl-3 text-grey    f-f-r text-smLa  rounded hover:text-white  hover:bg-primary mdL:hover:bg-transparent mdL:hover:text-blue-700 mdL:p-0 "}
                  aria-current="page"
                >
                  Sign Up
              </Link>
              }



              </li>
              
            </ul>

          </div>
        </div>
      </nav>
    </div>

  );
            }

}
