import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import {UserProvider} from './components/UserProvider';


ReactDOM.render(
  <React.StrictMode>
      <UserProvider>
      <App/>
      </UserProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
