import React, {useContext, useState } from 'react';
import {useNavigate, Link} from 'react-router-dom'
import { UserContext } from './UserProvider.js';
import {hostName} from './HostName.js';
import { instance } from './Utils.js';


const SideNavbar = () => {

  let navigate = useNavigate();
  let providerValue = useContext(UserContext);
  let unread_message = providerValue.userState.chatChannel.reduce((accum, ele)=> {let unread = ele['channel_name'].indexOf(providerValue.userState.userInfo['username']) == 0 ? ele['unread_user1'] : ele['unread_user2']; return accum + unread; }, 0 );
  let friendrequest = providerValue.userState.friend_request.length;
  const logout = () =>{

    //logs user out by closing the backend session
    instance.post('/forms/signout/', {}).then(
        res => {
            providerValue.updateUserState({type : 'setAuth', authenticated : false});
            navigate('/', {replace : true});
        }
     ).catch( 
        err => {console.log(err)} );
}

  return (
    <>
       <div className="sidebar-scroll h-full min-h-screen relative bg-white pt-6 lg2:pt-[44px] overflow-y-auto max-h-screen">
       <div className="h-fit"><Link to="/exchange"><img src={`${hostName}/static/decentral_logo_small.png`} className='px-9' alt="Decentral Logo" /></Link></div>

       <div className='mt-12 h-[calc(100vh-140px)] lg2:h-[calc(100%-100px)] flex flex-col justify-between'>
            <div>
              <div className='text-not-black px-6 mb-5'>Menu</div>
              <Link to="/exchange"><div className={`relative px-6 py-2 mb-5 text-[17px] ${window.location.pathname.includes("/exchange") ? "text-main-color" : "text-not-active"} grid grid-cols-[40px,1fr] items-center`}><img src={`${hostName}/static/portfolioActive.svg`} className={window.location.pathname.includes("/exchange") ? "w-[22px]" : "hidden w-[22px]"} alt="icon" /> <img src={`${hostName}/static/portfolioNotActive.svg`} className={ !window.location.pathname.includes("/exchange") ? "w-[22px]" : "hidden w-[22px]"} alt="icon" /> Exchange <div className={`${window.location.pathname.includes("/exchange") ? "visible" : "invisible"} absolute top-1/2 -translate-y-1/2 left-0 h-[60px] w-1 rounded-tr-md rounded-br-md bg-main-color`}></div><img src={`${hostName}/static/activeIcon.svg`} className={`${window.location.pathname.includes("/exchange") ? "invisible lg2:visible" : "invisible"} absolute top-1/2 -translate-y-1/2 right-0`} alt="icon" /> </div></Link>
              <Link to="/funding"><div className={`relative px-6 py-2 mb-5 text-[17px] ${window.location.pathname.includes("/funding") ? "text-main-color" : "text-not-active"} grid grid-cols-[40px,1fr] items-center`}><svg width="25" height="25" className={window.location.pathname.includes("/funding") ? "stroke-main-color" : "stroke-[#A3A3A3]"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 1V23"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M17 5H9.5C8.57174 5 7.6815 5.36875 7.02513 6.02513C6.36875 6.6815 6 7.57174 6 8.5C6 9.42826 6.36875 10.3185 7.02513 10.9749C7.6815 11.6313 8.57174 12 9.5 12H14.5C15.4283 12 16.3185 12.3687 16.9749 13.0251C17.6313 13.6815 18 14.5717 18 15.5C18 16.4283 17.6313 17.3185 16.9749 17.9749C16.3185 18.6313 15.4283 19 14.5 19H6"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg> Funding <div className={`${window.location.pathname.includes("/funding") ? "visible" : "invisible"} absolute top-1/2 -translate-y-1/2 left-0 h-[60px] w-1 rounded-tr-md rounded-br-md bg-main-color`}></div><img src={`${hostName}/static/activeIcon.svg`} className={`${window.location.pathname.includes('/funding') ? "invisible lg2:visible" : "invisible"} absolute top-1/2 -translate-y-1/2 right-0`} alt="icon" /></div></Link>
              <Link to="/friends"><div className={`relative px-6 py-2 mb-5 text-[17px] ${window.location.pathname.includes("/friends") ? "text-main-color" : "text-not-active"} grid grid-cols-[40px,1fr] items-center`}><img src={`${hostName}/static/friendsActive.svg`} className={window.location.pathname.includes("/friends") ? "w-[27px]" : "hidden w-[27px]"} alt="icon" /> <img src={`${hostName}/static/friendsNotActive.svg`} className={ !window.location.pathname.includes("/friends") ? "w-[27px]" : "hidden w-[27px]"} alt="icon" /> <div className=' flex justify-between'><div>Friends</div> <div class={`text-center rounded-full py-[5px] text-smLa font-medium text-white bg-[#0085FF] h-[32px] w-[32px] ${friendrequest > 0 ? "" : "hidden"}`}> {friendrequest}  </div> </div> <div className={`${window.location.pathname.includes("/friends") ? "visible" : "invisible"} absolute top-1/2 -translate-y-1/2 left-0 h-[60px] w-1 rounded-tr-md rounded-br-md bg-main-color`}></div><img src={`${hostName}/static/activeIcon.svg`} className={`${window.location.pathname.includes("/friends") ? "invisible lg2:visible" : "invisible"} absolute top-1/2 -translate-y-1/2 right-0`} alt="icon" /> </div></Link>
              <Link to="/chat"><div className={`relative px-6 py-2 mb-5 text-[17px] ${window.location.pathname.includes("/chat") ? "text-main-color" : "text-not-active"} grid grid-cols-[40px,1fr] items-center`}><img src={`${hostName}/static/chatActive.svg`} className={window.location.pathname.includes("/chat") ? "w-[25px]" : "hidden w-[25px]"} alt="icon" /> <img src={`${hostName}/static/chatNotActive.svg`} className={ !window.location.pathname.includes("/chat") ? "w-[25px]" : "hidden w-[25px]"} alt="icon" /> <div className='flex justify-between'><div>Chat</div> <div class={`${unread_message >0 ? "" : "hidden"} text-center rounded-full py-[5px] text-smLa font-medium text-white bg-[#0085FF] h-[32px] w-[32px]`}> {unread_message} </div></div><div className={`${window.location.pathname.includes("/chat") ? "visible" : "invisible"} absolute top-1/2 -translate-y-1/2 left-0 h-[60px] w-1 rounded-tr-md rounded-br-md bg-main-color`}></div><img src={`${hostName}/static/activeIcon.svg`} className={`${window.location.pathname.includes("/chat") ? "invisible lg2:visible" : "invisible"} absolute top-1/2 -translate-y-1/2 right-0`} alt="icon" /> </div></Link>
            </div>

              <div className="w-full">
              <Link to="/settings"><div className={`relative px-6 py-2 mb-5 text-[17px] ${window.location.pathname.includes("/settings") ? "text-main-color" : "text-not-active"} grid grid-cols-[40px,1fr] items-center`}><img src={`${hostName}/static/settingsActive.svg`} className={window.location.pathname.includes("/settings") ? "w-[25px]" : "hidden w-[25px]"} alt="icon" /> <img src={`${hostName}/static/settingsNotActive.svg`} className={ !window.location.pathname.includes("/settings") ? "w-[25px]" : "hidden w-[25px]"} alt="icon" /> Settings <div className={`${window.location.pathname.includes("/settings") ? "visible" : "invisible"} absolute top-1/2 -translate-y-1/2 left-0 h-[60px] w-1 rounded-tr-md rounded-br-md bg-main-color`}></div><img src={`${hostName}/static/activeIcon.svg`} className={`${window.location.pathname.includes("/settings") ? "invisible lg2:visible" : "invisible"} absolute top-1/2 -translate-y-1/2 right-0`} alt="icon" /> </div></Link>
               <button onClick={logout}><div className="relative px-6 py-2 mb-3 text-[17px] text-not-red grid grid-cols-[40px,1fr] items-center"><img src={`${hostName}/static/logout.svg`} className='w-[27px]' alt="icon" /> Logout <div className="hidden absolute top-1/2 -translate-y-1/2 left-0 h-[60px] w-1 rounded-tr-md rounded-br-md bg-main-color"></div></div></button>
              </div>
       
        </div>

       </div>
    </>
  )
}

export default SideNavbar
