import {createContext, useEffect, useReducer} from "react";
import useWebSocket from 'react-use-websocket';
import { instance } from "./Utils";
import {webSocketURL} from './HostName';


export const UserContext = createContext({userState : {}, updateUserState : ()=>{} });

export function UserProvider({children}){
  console.log("1) Provider");

  let timer = null;
  const TIMER_LIMIT = 1000 * 60 * 10 // 10 MINUTES (number of miliseconds)

  const socketUrl = `${webSocketURL}/ws/socket-server/`;

  const {
    sendMessage,
    sendJsonMessage,
    lastMessage,
    lastJsonMessage,
    readyState,
    getWebSocket,
  } = useWebSocket(socketUrl, {
    onOpen: () => console.log('opened'),
    //Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: (closeEvent) => userState.authenticated, // when user is authenticated then try to reconnect

    onMessage: (event)=>{
      console.log(event);
      console.log(event['data']);
       
      if('data' in event){
        let jsonObj = JSON.parse(event['data']);
        console.log(jsonObj);
        
        if(jsonObj['type'] == 'chat_update'){
          updateChat();
        }
        else if(jsonObj['type'] == 'wallet_update'){
          updateWallet();
        }
        else if(jsonObj['type'] == 'balance_update'){
          updateBalance();
        }
        else if(jsonObj['type'] == 'exchange_update'){
          updateExchange();
        }
        else if( jsonObj['type'] == 'friendrequest_update'){
          updateFriendRequest();
        }
        else if(jsonObj['type'] == 'friendlist'){
          updateFriends();
        }
        else if(jsonObj['type'] == 'transaction_update'){
          updateTransactions();
        }
        else if(jsonObj['type'] == 'session_over'){
          endSession();
        }

      }
    },
  });

  function openChannel(channel_name){
    sendJsonMessage({'type': 'open_channel', 'channel_name' : channel_name});
  }
  
  //JSON.parse(window.localStorage.getItem('loggedIn')) == 'true'
  const [userState, dispatch] = useReducer(reducer, {
    authenticated : JSON.parse(window.localStorage.getItem('loggedIn')) === 'true', ethereum : {price: 0.0, contractGasFee : 0.0, simpleGasFee : 0.0},
    dataReady : false, userInfo : {}, allUsers : [], findFriends: [], friends : [], friend_request : [],
    chatChannel: [{'channel_name' : '', 'last_message': '', 'user1' : {'username' : '', 'first_name': '', 'last_name': '',}, 'user2' : {'username' : '', 'first_name': '', 'last_name': '',} , 'date_created' : '', 'date_updated' : '',  }], 
    chatLog : {'': []}, wallet : {}, cryptoList : [], cryptoExchange: [{'uuid': 'bitcoin', 'name': 'Bitcoin', 'current_price': 0.0,  'change_24hr' : 0.0, 'image': 'static/btc.svg/', 'symbol': 'btc',  },], 
    chart : [], serviceFee : {}, userBalance : {}, transactions : [], 
});


  function reducer(userState, action){
    console.log("Inside Reducer");
      switch(action.type){    
        case 'setAuth':
          console.log("Updating authentication");
          console.log(JSON.stringify(String(action.authenticated)));
          window.localStorage.setItem('loggedIn', JSON.stringify(String(action.authenticated)));
          return({
            ...userState,
            authenticated  : action.authenticated,
          });

        case 'dataReady':
          console.log("Data is ready");
          return({
            ...userState,
            dataReady        : action.dataReady,
          });

        case 'setHome':
          console.log("Updating userState");  
        return({
          ...userState,
          allUsers       : action.allUsers,
          friends        : action.friends,
          findFriends    : action.findFriends,
          userInfo       : action.userInfo
          
          });

        case 'updateTransactions':
        console.log("updating transactions");
        
        // sorts transactions using custom comparator function
        action.transactions.sort((a,b) => { let a_date = new Date(a['date_updated']); let b_date = new Date (b['date_updated']); return (b_date - a_date); } );
        return({
          ...userState,
          transactions : action.transactions,
        });

        case 'updateAssets':
        console.log("updating assets");
        var assetObj = {}
        /*
        assetObj Structure

        {
          '0xsFwqeeQer' : {
            'bitcoin' : 0.23
          },
          '0xEWDFwqeesa' : {
            'ethereum' : 1.123,
            'dai'      : 0.00,
            'link'     : 231.1
          }
          '0x1DsFwqeeds' : {
            'polkadot' : 50.21,
          }  

        }
        */
        
        if(Array.isArray(action.assets)){
          action.assets.forEach(ele => { 
            
            if(ele['wallet']['account'] in assetObj){
              assetObj[ele['wallet']['account']][ele['coin']['uuid']] =  ele['amount'];
            }
            else{
              assetObj[ele['wallet']['account']] = {};
              assetObj[ele['wallet']['account']][ele['coin']['uuid']] =  ele['amount'];
            }
          
          })
        }
        console.log(assetObj);
        return({
          ...userState,
          assets : assetObj,
        });

        case 'updateWallet':
        console.log("updating Wallet");
        var walletObj = {}

        /*
        walletObj Structure

        {
          'bitcoin'  : [{'account' : 'sdaeaw'}, {'account' : '12asdaf'}, ],
          'ethereum' : [{'account' : 'sdaeaw'}, {'account' : '12asdaf'}, {'account' : '12asdaf'}, ],
          'polkadot' : [{'account' : 'sdaeaw'}, {'account' : '12asdaf'}, ]
        }
        */
        if(Array.isArray(action.wallet)){
          action.wallet.forEach(ele => { (ele['blockchain'] in walletObj) ? walletObj[ele['blockchain']].push(ele) : walletObj[ele['blockchain']] = [ele] }); 
        }
        console.log(walletObj);
        var listed_blockchain = ['bitcoin', 'ethereum', 'polkadot']
        listed_blockchain.forEach(ele =>{ if(ele in walletObj){}else{walletObj[ele] = [];  } });
        console.log(walletObj);
        return({
          ...userState,
          wallet : walletObj,
        });

        case 'updateFriendRequest':
          console.log("Updating FriendRequest");
          return({
            ...userState,
            friend_request : action.friend_request,
        });

        case 'updateFriends':
          console.log("Updating Friends");
          return({
            ...userState,
            friends : action.friends
        });

        case 'updateFindFriends':
          console.log("Updating Find Friends");
          return({
            ...userState,
            findFriends : action.findFriends
        });

        case 'updateChatLog':
          console.log("Updating Chat Log");
          
          var chatObj = {}
          action.chatLog.forEach(ele => { 
            
          if(ele['channel_name'] in chatObj){ 
            chatObj[ele['channel_name']].push(ele);
          }
          else {
            chatObj[ele['channel_name']] = [ele];
          }
        })

          return({
            ...userState,
            chatLog : chatObj
        });
      
        case 'updateChatChannel':
          console.log("Updating Chat Channel");
          
          // filters channel to hide specific channels from user (hiding deleted channels)
          var channelObj = {}
          channelObj = action.chatChannel.filter(ele => {
            openChannel(ele['channel_name']);
            if(ele['user1']['username'] == userState.userInfo['username']){
              if(ele['hide_user1'] == false){
                return true
              }
              else{
                return false 
              }
            }
            else if(ele['user2']['username'] == userState.userInfo['username']){
              if(ele['hide_user2'] == false){
                return true
              }
              else{
                return false 
              }
            }
          }); 

          return({
            ...userState,
            chatChannel : channelObj
        });

  
        case 'bannerUpdate':
          console.log("Updating Banner");
          return({
            ...userState,
            cryptoList : action.cryptoList,
            priceReady : true,
        });

        case 'exchangeUpdate':
          console.log("Updating Exchange");
          return({
            ...userState,
            cryptoExchange : action.cryptoExchange,
        });

        case 'updateBalance':
          console.log("Updating Balance");
          return({
            ...userState,
            userBalance : action.userBalance,
        });

        case 'updateServiceFee':
          console.log("Updating service fee");
          return({
            ...userState,
            serviceFee : action.serviceFee,
        });

        default:
          console.log("Updating Default");
        return({
          ...userState,
          authenticated  : false,
          priceReady     : false,
        });
      }
  };

  async function updateFriendRequest(){
    var res = await instance.get('/allposts/friendrequests/');
    dispatch({type : 'updateFriendRequest', friend_request : res.data});
  }

  async function updateFriends(){

    var res1 = await instance.get('/allposts/friends/');
    var res2 = await instance.get('/allposts/find_friends/');
    
    dispatch({type : 'updateFriends', friends : res1.data});
    dispatch({type : 'updateFindFriends', findFriends : res2.data});

  }

  async function updateWallet(){

    var res = await instance.get('/allposts/wallet/');
    dispatch({type : 'updateWallet', wallet : res.data} );      
  }

  async function updateBalance(){

    var res = await instance.get('/allposts/userBalance/');
    dispatch({type : 'updateBalance', userBalance : res.data} );      
  }

  async function updateChat(){

    var res1 = await instance.get('/allposts/chatlog/');
    var res2 = await instance.get('/allposts/chatchannel/');
    
    dispatch({type : 'updateChatLog', chatLog : res1.data} );
    dispatch({type : 'updateChatChannel', chatChannel : res2.data} );      
  }

  async function updateTransactions(){

    var res = await instance.get('/allposts/transaction/');
    dispatch({type : 'updateTransactions', transactions : res.data} );
  }

  function endSession(){
     //logs user out by closing the backend session
     instance.post('/forms/signout/', {}).then(
        res => {
            window.localStorage.setItem("loggedIn", false);
            dispatch({type : 'setAuth', authenticated : false});
        }
     ).catch( 
        err => {console.log(err)} );
  }


// Executes every minute
async function updateExchange(){
  var res = await instance.get('/allposts/exchange/');
  dispatch({type : 'exchangeUpdate',  cryptoExchange : res.data});
}

  // Initial setup is used when the user refresh their page
  // we do a http get request to retrieve all their info
  async function initialSetUp(){

    let promiseArray = [ 
      instance.get('/allposts/friends/'), 
      instance.get('/allposts/find_friends/'), 
      instance.get('/allposts/allusers/'), 
      instance.get('/allposts/user/'), 
      instance.get('/allposts/friendrequests/'), 
      instance.get('/allposts/exchange/'), 
      instance.get('/allposts/chatlog/'), 
      instance.get('/allposts/chatchannel/'), 
      instance.get('/allposts/wallet/'),
      instance.get('/allposts/serviceFee/'),
      instance.get('/allposts/userBalance/'),
      instance.get('/allposts/transaction/'),
      instance.get('/allposts/assets/')   
    ]

  let res = await Promise.all(promiseArray).then((res) =>{
    dispatch({type : 'setHome', friends : res[0].data, findFriends : res[1].data, allUsers : res[2].data, userInfo: res[3].data});
    dispatch({type : 'updateFriendRequest', friend_request : res[4].data});
    dispatch({type : 'exchangeUpdate', cryptoExchange : res[5].data});   
    dispatch({type : 'updateChatLog', chatLog : res[6].data});
    dispatch({type : 'updateChatChannel', chatChannel : res[7].data});
    dispatch({type : 'updateWallet', wallet : res[8].data});
    dispatch({type : 'updateServiceFee', serviceFee : res[9].data});
    dispatch({type : 'updateBalance', userBalance : res[10].data});
    dispatch({type : 'updateTransactions', transactions : res[11].data});
    dispatch({type : 'updateAssets', assets : res[12].data});
    
    dispatch({type : 'dataReady', dataReady: true});
    dispatch({type : 'setAuth', authenticated : true});
  }
  ).catch((err) => {console.log(err)});

}

function setTimer(){
  clearTimeout(timer);
  timer = setTimeout(()=>{
    console.log("resetting timer");
    endSession();
  }, TIMER_LIMIT);
}

function stopTimer(){
  clearTimeout(timer);
}

async function checkAuthSession(){
  var res = await instance.get('/forms/credentials/')
  .then((res) =>{
    window.localStorage.setItem("loggedIn", `${res.data['authenticated']}`);
    dispatch({type : 'setAuth',  authenticated : res.data['authenticated']});
  })
  .catch((err) => console.log(err));
}

  // Whenever the browser refresh this will execute
  // We want to do an initial setup (call to the backend server)
  // To retrieve user's info and price info for banner
  useEffect(()=>{
    checkAuthSession();
    
    if(userState.authenticated === true){
      console.log("doing initial setup");
      initialSetUp();
      setTimer();
      window.addEventListener('keydown', setTimer);
      window.addEventListener('click', setTimer);
    }
    if(userState.authenticated == false){
      window.removeEventListener('keydown', stopTimer);
      window.removeEventListener('click', stopTimer);
    }

    updateExchange();
    return () => {
      window.removeEventListener('keydown', stopTimer);
      window.removeEventListener('click', stopTimer);
    }
  }, [userState.authenticated]);



    return(
        <UserContext.Provider value={{userState : userState, updateUserState : dispatch, openChannel : openChannel} } >
            {children}
        </UserContext.Provider>
    );

    }