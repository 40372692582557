import {useContext, Suspense, lazy} from 'react';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import { UserContext } from './components/UserProvider.js';
import Navbar from './components/Navbar.js';

const SignUpPage = lazy(()=>import ('./pages/SignUpPage.js'));
const SignInPage = lazy(()=>import ('./pages/SignInPage.js'));
const LandingPage = lazy(()=>import ('./pages/LandingPage.js'));
const Exchange = lazy(()=>import ('./pages/Exchange.js'));
const Funding = lazy(()=>import ('./pages/Funding.js'));
const Chat = lazy(()=>import ('./pages/Chat.js'));
const Friends = lazy(()=>import ('./pages/Friends.js'));
const Settings = lazy(()=>import ('./pages/Settings.js'));
const ResetPassword = lazy(()=>import ('./pages/ResetPassword.js'));
const ForgotPassword = lazy(()=>import ('./pages/ForgotPassword.js'));
const TransactionStatus = lazy(()=>import ('./pages/TransactionStatus.js'));

export default function App() {
  
  const providerValue = useContext(UserContext);
  console.log("2) App %s", providerValue.userState.authenticated);
  
  return(
    <Suspense fallback={null}>
     <Router>
        <Routes>
          
          <Route path="home/" element={<LandingPage/>} />
          <Route path="signup/*" element={<><Navbar location={"signup"}/> <SignUpPage/> </>}/>
          <Route path="reset_password/*" element={<ResetPassword/>} />
          <Route path="forgot_password/*" element={<ForgotPassword/>} />
          
          {/* 
          
            TODO: Replace empty data rendering page with skeleton pages 
            Could potentially use current pages with empty data instead
            of providerValue data

          */}
          {(providerValue.userState.authenticated ) ? <Route path="/transaction_status/*" element={providerValue.userState.dataReady ? <TransactionStatus/>: <></>}/> : <Route path="/transaction_status" element={<LandingPage/>}/>}
          {(providerValue.userState.authenticated ) ? <Route path="/"           element={providerValue.userState.dataReady ? <Exchange/> : <></>}/>   : <Route path="/" element={<LandingPage/>}/>}
          {(providerValue.userState.authenticated ) ? <Route path="/signin/*"   element={providerValue.userState.dataReady ? <Exchange/> : <></>}/>   : <Route path="signin/*" element={<><Navbar location={"signin"}/> <SignInPage/> </>}/>}
          {(providerValue.userState.authenticated ) ? <Route path="/exchange/*" element={providerValue.userState.dataReady ? <Exchange/> : <></>}/>   : <Route path="/exchange" element={<LandingPage/>}/>}
          {(providerValue.userState.authenticated ) ? <Route path="/funding/*"  element={providerValue.userState.dataReady ? <Funding/> : <></>}/>    : <Route path="funding/*" element={<><Navbar location={"signin"}/> <SignInPage/> </>}/>}
          {(providerValue.userState.authenticated ) ? <Route path="/friends/*"  element={providerValue.userState.dataReady ? <Friends/> : <></>}/>    : <Route path="friends/*" element={<><Navbar location={"signin"}/> <SignInPage/> </>}/>}
          {(providerValue.userState.authenticated ) ? <Route path="/chat/*"     element={providerValue.userState.dataReady ? <Chat/> : <></>}/>       : <Route path="chat/*" element={<><Navbar location={"signin"}/> <SignInPage/> </>}/>}
          {(providerValue.userState.authenticated ) ? <Route path="/settings/*" element={providerValue.userState.dataReady ? <Settings/> : <></>}/>   : <Route path="settings/*" element={<><Navbar location={"signin"}/> <SignInPage/> </>}/>}  
        </Routes>
    </Router>
  </Suspense>

  );
}

